body {
  font-family:'SF Compact Display Medium';
  font-weight:normal
}


.App {
  text-align: center;
}

.userCircle {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background: rgb(174, 214, 238);
  background: radial-gradient(circle, rgb(174, 214, 238) 0%, rgba(148,187,233,1) 100%);
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

#canvas {
 min-height: 100vh;
 min-width: 100vw;
 background: #6DD5FA;  /* fallback for old browsers */
 background: -webkit-linear-gradient(to top, #FFFFFF, #6DD5FA, #2980B9);  /* Chrome 10-25, Safari 5.1-6 */
 background: linear-gradient(to top, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 /* background: linear-gradient(180deg, #003BFF 0%, #A7E4FF 100%) 0% 0% no-repeat padding-box; */
}


#blocker {
  position: absolute;
  /* background-color: rssssgba(255, 0, 0, 0.5); */
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.grid {
  background-size: 40px 40px;
  background-color: #e7eff9;
  background-image:
    linear-gradient(to right, grey 1px, transparent 1px),
    linear-gradient(to bottom, grey 1px, transparent 1px),
    linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%);
}



.circle {
  background: #bf1e2c;
  width: 10000px;
  height: 10000px;
  margin: auto;
  border-radius: 100%;
  overflow: hidden; 
}

.circle:span {
  display: block;
  font-size:240px;
  color: #fff;
  height: 500px;
  width: 500px;
  text-align: center;
  padding-top: 24%;
}

/*.circle:hover {
  background: #070338;
}
*/

.circle {
  animation:grow 2s infinite;

}

@keyframes grow {
 0% {
  -webkit-transform: scale( 0 );-moz-transform: scale( 0 );-o-transform: scale( 0 );-ms-transform: scale( 0 );transform: scale( 0 );
  }
  
  50% {
  -webkit-transform: scale( .5 );-moz-transform: scale( .5 );-o-transform: scale( .5 );-ms-transform: scale( .5 );transform: scale( .5 );
  }
  
  100% {
  -webkit-transform: scale( 1 );-moz-transform: scale( 1 );-o-transform: scale( 1 );-ms-transform: scale( 1 );transform: scale( 1 );
  }
}


.fade {
  display: block;
  background:
    radial-gradient(
      transparent 21%, rgba(233,233,246, .8) 21%,
      rgba(233,233,246, .8) 22%,transparent 28%);
    /* radial-gradient(
      transparent 36%,rgba(233,233,246, .8) 40%,
      rgba(233,233,246, .8) 42%,transparent 50%),
    radial-gradient(
      transparent 60%,rgba(233,233,246, .8) 65%,
      rgba(233,233,246, .8) 66%,transparent 70%); */
  background-size:0 0,0 0,0 0;
  background-position:center;
  background-repeat:no-repeat;
  animation:change 5s linear 1;
}

@keyframes change{
  0% {
    background-size:0 0,0 0,0 0;
  }

  50% {
    background-size:100% 100%,100% 100%,100% 100%;
  }

  100% {
    background-size:200% 200%,200% 200%,200% 200%;
  }
}

.leftMenu {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 20px;
  text-align: center;
  color: white;
  z-index: 1;
}

.topMenu {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  text-align: center;
  font: Medium 16px/19px SF Compact Display;
  letter-spacing: 0;
  color: #2886BF;
  z-index: 1;
}

.blur {
  filter: blur(5px) brightness(0.6);
}

.iconWithText {
  display: flex;
  align-items:center;
}

.iconWithText img {
  margin: 5px 10px;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out {
	opacity: 1;
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.tiktok-embed {
  margin: auto !important;
}

.vz-wrapper {
  position: relative;
  height: 400px;
  width: 100%;
  /* background-image: ; */
  /* background: -webkit-gradient(radial, center center, 0, center center, 460, from(#396362), to(#000000));
  background: -webkit-radial-gradient(circle, #396362, #000000);
  background: -moz-radial-gradient(circle, #396362, #000000);
  background: -ms-radial-gradient(circle, #396362, #000000);
  box-shadow: inset 0 0 160px 0 #000; */
  cursor: pointer;
}

.vz-wrapper.-canvas {
  position: absolute;
  top: 0%;
  left: 0%;
  /* transform: translate(-50%, -50%); */
  height: initial;
  width: initial;
  background: transparent;
  box-shadow: none;
}

@media screen and (min-width: 420px) {
  .vz-wrapper {
      /* box-shadow: inset 0 0 200px 60px #000; */
  }
}