 /* The Modal (background) */
 .modal {
    display: absolute; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    color: #434343;
    font: Medium 24px/29px SF Compact Display;
  }

  .modalClose {
    position: absolute;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
  }
  
  /* Modal Content/Box */
  .modal-login {
    background: #fff 0% 0% no-repeat padding-box;
    position: relative;
    border-radius: 8px;
    opacity: 1;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 650px;
    height: 660px;
  }

  .modal-login p {
      font-size: 1.4em;;
  }

  .logo {
    width: 90px;
    margin-top:40px;
    margin-bottom:40px
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  } 

  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px auto;
    /* height: 50%; */
    width: 100%;
  }

  .social span {
    margin: 30px 5px;
    cursor: pointer;
  }


  .loginbtn {
    width: 164px;
    height: 40px;
    /* margin: auto; */
    background: transparent linear-gradient(97deg, #F64558 0%, #2A4690 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000033;
    color: white;
    margin: 20px auto;
    text-align: center;
    /* border: 1px solid lightgray; */
    border-radius: 20px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .selected {
    color: #2886bf
  }

.logininput {
  width:247px; 
  height:38px;
  border: 1px solid #DADEE6;
  padding: 5px 10px;
  border-radius:4px;
  margin: 10px auto;
}