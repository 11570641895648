.card {
    width:400px;
    height:600px;
    background-color: white;
}

.card-img-top {
    max-width: 400px;
}

.card-body {
    padding: 15px;
}

.card-title {
    text-align: left;
    font-size: 1.5em;
    margin: 5px 0;
    word-wrap: break-word;
}

.card-text {
    text-align: justify;
    overflow-y: auto;
    word-wrap: break-word;
}

.linkicon {
    position: absolute;
    top:155px;
    left:155px;
    color: lightgray;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: white;
    padding: 5px;
}

.linkicon:hover {
    color: gray;
}

#info {
    position: absolute;
    bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.map {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 2px;
    color: #898989;
    cursor: pointer;
}

.comments {
    display: flex; 
    font-size: 12px;
    /* font-weight: lighter; */
    position: absolute;
    background-color: white;
    overflow: hidden;
    width: 400px;
    height: 100%;
    right: -400px;
    top:0;
    border-radius: 25px;
    flex-direction:column;
}

.commentsTitle {
    visibility: hidden;
    position: absolute;
    width: 100%;
    top: -40px;
    left:20px;
    text-align: left;
    font-family: "SF Compact Display Thin";
    color:white;
}

.commentsTitle.show {
    visibility: visible;
}

.commentUser {
    background-color: #F7F7F7;
    height:80px;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.comments .avatar {
    width: 40px;
    height: 40px;
    margin-left: 20px;
}

.commentList {
    text-align: center;
    width: 100%;
    height: 75%;
    padding:10px;
}

.addComment {
    background:white;
    max-height: 25%;
    height:65px;
    padding:5px;
    border-top: 1px solid #EFEFEF;
}

.commentInput {
    height: 100%;
    width: 90%;
    margin:auto;
    border:none;
    line-height: 100%;
}

.src {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    margin: 2px;
    color: white;
}

.ig {
    background: rgb(255,204,94);
    background: linear-gradient(15deg, rgba(255,204,94,1) 0%, rgba(242,146,62,1) 25%, rgba(236,67,44,1) 38%, rgba(175,26,117,1) 70%, rgba(83,77,155,1) 93%, rgba(61,90,165,1) 99%);
    
}

.instagram-media-rendered {
    border: none !important;
}

.yt {
    background: #c4302b;   
}

.img {
    background: #104f83;
}

.sc {
    background: #ff7700;
}

.sp {
    background: #1db954;
}

.text {
    background: #00bce4;
}

.music {
    background: #ff4f81;
}

.twitchChannel {
    background: #6441a5;
}

.tiktok {
    background: rgba(1,1,1,1);
    /* background: linear-gradient(15deg, rgba(238,29,82,1) 0%, rgba(1,1,1,1) 50%, rgba(105,201,208,1) 100%);  */
}

.tiktokIcon {
    content: url(/images/tiktokicon.svg);
    transform: scale(.75);
}


.title {
    width: 600px;
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 2px;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.title>span {
    margin:10px;
    max-width: 410px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title .actions {
    width: 180px;
    height: 100%;
    background: #F2F0F0 0% 0% no-repeat padding-box;
    align-self: flex-end;
    right:0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#111
}

.title .actions>a {
    margin:10px;
    color:#111;
}

.def {
    position: relative;
    width: 60px;    
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: #898989;
    text-align: center;
    margin: 2px;
    line-height: 70px;
    font-size: 30px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.super {
   position: absolute;
   display: inline;
   top:-25px;
   right:5px;
   font-size:12px;
}

.red{
    color:red;
 }

.blue{
    color:#104f83;
 }



 #selector {
     width: 100px;
     height: 100px;
     background: transparent radial-gradient(circle, rgb(255,255,255,1), rgb(255,255,255,0));
     border-radius: 50%;
     /* border: 1px solid black; */
     z-index: 10;
     display:none;
 }